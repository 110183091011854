/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useContext, useEffect } from "react"
import { get } from "lodash";
import AuthContext from '../context/AuthProvider';
import ContentfulContext from "../context/ContentfulProvider";
import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import Button from "../components/atoms/Button/Button"
import Dialog from '../components/atoms/Dialog/Dialog';
import FormInputField from '../components/atoms/FormInputField/FormInputField';
import { handleLink } from "../helpers/general";

import * as styles from './signup.module.css';

const SignupPage = () => {
    const [dialogMessage, setDialogMessage] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');

    const auth = useContext(AuthContext);
    const handleRegistration = auth && auth.signup;
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    const [isAttempting, setAttempting] = useState(false);

    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const [fields, setFields] = useState({
        email: '',
        first_name: '',
        last_name: '',
        authentication: {'new_password': ''},
        confirm_password: ''
    });

    const [emptyError, setEmptyError] = useState({
        email: false,
        first_name: false,
        last_name: false,
        password: false,
        confirm_password: false,
    });

    useEffect(() => {
        if (isLoggedIn && typeof window !== 'undefined') {
            window.location = handleLink('/account/');
        }

    }, [isLoggedIn])

    const handleChange = (id, fieldValue) => {
        const newField = {};

        if (id === 'password') {
            newField['authentication'] = {'new_password': fieldValue};
        }
        else {
            newField[id] = fieldValue;
        }
        
        setFields({...fields, ...newField});
    }

    const clearDialog = () => {
        setDialogMessage(false);
    }

    const attemptRegistration = (e) => {
        e.preventDefault();
        setAttempting(true);
        const valid = validateRequiredFields()

        if (valid) {
            if (validatePassword(fields['authentication'].new_password, fields['confirm_password'])) {
                handleRegistration(fields).then(response => {
                    // console.log('Registration response', response)
                    setDialogTitle(get(translations, 'accountSuccess.title', 'Account creation successful'));
                    setDialogMessage(response);
                }).catch(error => {
                    setDialogTitle(get(translations, 'accountUnsuccessful.title', 'Account creation unsuccessful'));
                    setDialogMessage(error);
                    setAttempting(false);
                });
            } else {
                setDialogTitle(get(translations, 'accountUnsuccessful.title', 'Account creation unsuccessful'));
                setDialogMessage(get(translations, 'passwordNotMatch.title', 'Passwords do not match.'));
                setAttempting(false);
            }
        } else {
            setDialogTitle(get(translations, 'accountUnsuccessful.title', 'Account creation unsuccessful'));
            setDialogMessage(get(translations, 'fillFields.title', 'Please fill all available fields in.'));
            setAttempting(false);
        }
    }

    const validatePassword = (pass, passConfirm) => {
        if (pass === passConfirm) {
            return true
        } else {
            return false
        }
    }

    const validateRequiredFields = () => {
        const newError = emptyError
        let isValid = true

        Object.keys(emptyError).forEach((item) => {
            if (item === 'password') {
                if (fields['authentication'].new_password !== '') {
                    newError['password'] = false
                } else {
                    newError['password'] = true
                    isValid = false
                }
            } else if (fields[item] !== '') {
                newError[item] = false
            } else {
                newError[item] = true
                isValid = false
            }
        });

        setEmptyError(newError)

        return isValid
    }

    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <Seo title="Sign up" />
            <Container>
                <PageTitle title={get(translations, 'createAccount.title', 'Create account')} />
                <form onSubmit={(e) => attemptRegistration(e)}>
                    <div className={styles.mainForm}>
                        <div className={styles.emailContainer}>
                            <FormInputField id='email' type='email' labelName={get(translations, 'emailAddress.title', 'Email address')} value={fields['email']} handleChange={handleChange} error={emptyError.email}/>
                        </div>
                        <div className={`${styles.row}`}>
                            <FormInputField id='password' type='password' labelName={get(translations, 'password.title', 'Password')} value={fields['authentication'].new_password} handleChange={handleChange} error={emptyError.password}/>
                            <FormInputField id='confirm_password' type='password' labelName={get(translations, 'confirmPassword.title', 'Confirm password')} value={fields['confirm_password']} handleChange={handleChange} error={emptyError.confirm_password}/>
                        </div>
                        <div className={`${styles.row}`}>
                            <FormInputField id='first_name' labelName={get(translations, 'firstName.title', 'First name')} value={fields['first_name']} handleChange={handleChange} error={emptyError.first_name}/>
                            <FormInputField id='last_name' labelName={get(translations, 'lastName.title', 'Last name')} value={fields['last_name']} handleChange={handleChange} error={emptyError.last_name}/>
                        </div>
                    </div>
                    
                    <div className={`formField ${styles.submit}`}>
                        <Button level="primary" type="buttonSubmit" disabled={isAttempting}>{isAttempting ? `${get(translations, 'pleaseWait.title', 'Please wait')}...` : get(translations, 'createAccount.title', 'Create an account')}</Button>
                    </div>
                    <Dialog open={dialogMessage ? true : false} title={dialogTitle} size="sm" hideBtnCancel disableBackdropClick onCancel={clearDialog} onOk={() => clearDialog()}>
                        {dialogMessage}
                    </Dialog>
                </form>
            </Container>
        </Layout>
        )
    } else {
        return null
    }
  }
  
  export default SignupPage
  